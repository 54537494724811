@import 'perfect-scrollbar/css/perfect-scrollbar.css';
@import './components/perfect-scrollbar';
@import './components/mat-menu';
@import './components/mat-button';
@import './components/mat-input';
@import './components/mat-dialog';
@import './components/mat-radio';
@import './components/mat-spinner';
@import './components/mat-snackbar';
@import './components/mat-tooltip';
@import './components/mat-slider';
@import './components/mat-checkbox';

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple) {
  color: var(--primary-text) !important;
}

.mat-form-field-disabled {
  opacity: 0.5 !important;
}
